import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class StockArticleService {
  // pageSize
  // currentPage
  pageSize=10
  currentPage=1
  sortFiled
  sortMode
  private isToastShown: boolean = false;

  constructor(private http: HttpClient, private messageService: MessageService) { }
  private handleApiError(error) {
    // Handle API error status here
    console.error('API call failed:', error);
  
    if (error.status !== 200) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Erreur inattendue' });
      this.isToastShown = true;
      setTimeout(() => {
        this.isToastShown = false;
      }, 5000);
    } 
    
  }
  async  sendData(data: any,dataFilter?) {

    this.currentPage=data.currentPage
    this.pageSize=data.pageSize    
  }
  async  getSort(data: any) {
    this.sortMode=data.sortMode
    this.sortFiled=data.sortFiled
    console.log( this.sortMode)
    
    }
    
  getEtablissements() {
    let promise = new Promise<any>((resolve) => {
     
      let url = `${environment.url}/etablissements?populate=* `;
      this.http
        .get(url)
        .toPromise()
  
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error; // Rethrow the error after handling it
                });
    });
    return promise;
  }
  async getDepots() {
    let promise = new Promise<any>((resolve) => {
      let url = `${environment.url}/stores`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;
                });
    });
    return promise;
  }
  async getDepotsWithContain(data?) {
    let promise = new Promise<any>((resolve) => {
      const searchTerm = data ? data : '';

      const attribute = 'name';
      let url = `${environment.url}/stores?filters[${attribute}][$contains]=${searchTerm}`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;
                });
    });
    return promise;
  }
  async getFournisseurs() {
    let promise = new Promise<any>((resolve) => {
      let url = `${environment.url}/Fournisseurs`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;
                });
    });
    return promise;
  }
  async getArticles() {
    let promise = new Promise<any>((resolve) => {
      let url = `${environment.url}/products`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;        });
    });
    return promise;
  }
  async getCouleursWithContain(data?) {
    let promise = new Promise<any>((resolve) => {
      const searchTerm = data ? data : '';

      const attribute = 'name';
      let url = `${environment.url}/colors?filters[${attribute}][$contains]=${searchTerm}`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;        });
    });
    return promise;
  }
  async getCouleurs() {
    let promise = new Promise<any>((resolve) => {
      let url = `${environment.url}/colors`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;        });
    });
    return promise;
  }
  async getTaillesWithContain(data?) {
    let promise = new Promise<any>((resolve) => {
      const searchTerm = data ? data : '';

      const attribute = 'name';
      let url = `${environment.url}/tailles?filters[${attribute}][$contains]=${searchTerm}`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;        });
    });
    return promise;
  }
  async getTailles() {
    let promise = new Promise<any>((resolve) => {
      let url = `${environment.url}/tailles`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;        });
    });
    return promise;
  }
  async getStock() {
    let promise = new Promise<any>((resolve) => {
      let url = `${environment.url}/getAllStockByProduct?pageSize=25`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;        });
    });
    return promise;
  }
  async getStockVariations() {
    let promise = new Promise<any>((resolve) => {
      let url = `${environment.url}/getAllStockByVariation?pageSize=30`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;        });
    });
    return promise;
  }
   async getAllStocks(fourniss?,article?,depot?) {
    let promise = new Promise<any>((resolve) => {
      let url = `${environment.url}/getAllStockByProduct?fltrfournisseur?&order=ASC&page=1&pageSize=30`;
      if (fourniss && fourniss.length > 0) {
        const fournissParam = fourniss.join(',');

        url += `&fltrfournisseur=${fournissParam}`;
    }
    
    if (article && article.length > 0) {
      const articleParam = article.join(',');
      url += `&fltrproduct=${articleParam}`;
    }

    if (depot && depot.length > 0) {
      const depotParam = depot.join(',');
      url += `&fltrstore=${depotParam}`;
    }
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;        });
    });
    return promise;
  }
  async getAllStocksVariations(fourniss?,article?,depot?,  fltrcolor?,fltrtaille?,) {
    let promise = new Promise<any>((resolve) => {
      let url = `${environment.url}/getAllStockByVariation?fltrfournisseur?&order=ASC&page=1&pageSize=30`;
      if (fourniss && fourniss.length > 0) {
        const fournissParam = fourniss.join(',');

        url += `&fltrfournisseur=${fournissParam}`;
    }
    
    if (article && article.length > 0) {
      const articleParam = article.join(',');
      url += `&fltrproduct=${articleParam}`;
    }

    if (depot && depot.length > 0) {
      const depotParam = depot.join(',');
      url += `&fltrstore=${depotParam}`;
    }
    if (fltrtaille && fltrtaille.length > 0) {
      const tailleParam = fltrtaille.join(',');
      url += `&fltrtaille=${tailleParam}`;
    }

    if (fltrcolor && fltrcolor.length > 0) {
      const colorParam = fltrcolor.join(',');
      url += `&fltrcolor=${colorParam}`;
    }
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;        });
    });
    return promise;
  }
  async getStocksByProduct(fourniss?,article?,depot?) {
    let promise = new Promise<any>((resolve) => {
      let url = `${environment.url}/getStockByProduct?&order=ASC&page=1&pageSize=30`;
      if (fourniss && fourniss.length > 0) {
        const fournissParam = fourniss.join(',');

        url += `&fltrfournisseur=${fournissParam}`;
    }
    
    if (article && article.length > 0) {
      const articleParam = article.join(',');
      url += `&fltrproduct=${articleParam}`;
    }

    if (depot && depot.length > 0) {
      const depotParam = depot.join(',');
      url += `&fltrstore=${depotParam}`;
    }
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;        });
    });
    return promise;
  }
  async getStocksByVariations(fourniss?,article?,depot?,  fltrcolor?,fltrtaille?) {
    let promise = new Promise<any>((resolve) => {
      let url = `${environment.url}/getStockByVariation?&order=ASC&page=1&pageSize=30`;
      if (fourniss && fourniss.length > 0) {
        const fournissParam = fourniss.join(',');

        url += `&fltrfournisseur=${fournissParam}`;
    }
    
    if (article && article.length > 0) {
      const articleParam = article.join(',');
      url += `&fltrproduct=${articleParam}`;
    }

    if (depot && depot.length > 0) {
      const depotParam = depot.join(',');
      url += `&fltrstore=${depotParam}`;
    }
    if (fltrtaille && fltrtaille.length > 0) {
      const tailleParam = fltrtaille.join(',');
      url += `&fltrtaille=${tailleParam}`;
    }

    if (fltrcolor && fltrcolor.length > 0) {
      const colorParam = fltrcolor.join(',');
      url += `&fltrcolor=${colorParam}`;
    }
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;        });
    });
    return promise;
  }
//**********************************updated code **********************************************
async getStocksByStore(fourniss?,article?,depot?,  fltrcolor?,fltrtaille?,queryName?) {
  let promise = new Promise<any>((resolve) => {
    let url = `${environment.url}/getStockByStore?`;
    const params = new HttpParams()

    .set('pageSize',this.pageSize)
    .set('page', this.currentPage)
    let filed
    if (this.sortFiled) {
      // Map the provided field to the corresponding API attribute
      switch (this.sortFiled) {
        case 'article':
          filed = 'code_article_generique';
          break;
        case 'depot':
          filed = 'store_name';
          break;
        case 'fournisseur':
          filed = 'fournisseur_name';
          break;
        case 'physique':
          filed = 'stockQty';
          break;
          case 'achat':
            filed = 'purchasedQuantity';
            break;
        case 'taille':
          filed = 'taille';
          break;
          case 'color':
            filed = 'taille';
            break;  
        default:
          break;
      }
    }
 
    if (this.sortMode === 1) {
      url += `&orderColumn=${filed}&order=DESC`;
    } else if (this.sortMode === -1) {
      url += `&orderColumn=${filed}&order=ASC`;
    }
  

    if (queryName ) {
      url += `&queryName=${queryName}`;
  }
    if (fourniss && fourniss.length > 0) {
      const fournissParam = fourniss.join(',');

      url += `&fltrfournisseur=${fournissParam}`;
  }
  
  if (article && article.length > 0) {
    const articleParam = article.join(',');
    url += `&fltrproduct=${articleParam}`;
  }

  if (depot && depot.length > 0) {
    const depotParam = depot.join(',');
    url += `&fltrstore=${depotParam}`;
  }
  if (fltrtaille && fltrtaille.length > 0) {
    const tailleParam = fltrtaille.join(',');
    url += `&fltrtaille=${tailleParam}`;
  }

  if (fltrcolor && fltrcolor.length > 0) {
    const colorParam = fltrcolor.join(',');
    url += `&fltrcolor=${colorParam}`;
  }
 
    this.http
      .get(url,{params})
      .toPromise()
      .then((res: any) => {
        if (Array.isArray(res.data)) {
          if (Array.isArray(res.data)) {
            // Sort the array based on the chosen field (achat or physique) if applicable
            if (this.sortFiled === 'achat' || this.sortFiled === 'physique') {
              res.data.sort((a, b) => {
                const fieldA = a[this.sortFiled] || 0; // handle potential undefined values
                const fieldB = b[this.sortFiled] || 0;
                return (this.sortMode === 1) ? fieldA - fieldB : fieldB - fieldA;
              });
            }
          }
        }
        resolve(res);
      })
      .catch((error) => {
        this.handleApiError(error);
        throw error;      });
  });
  return promise;
}

async getStocksBySommeStocks(fourniss?,article?,depot?,  fltrcolor?,fltrtaille?,queryName?) {
  let promise = new Promise<any>((resolve) => {
    let url = `${environment.url}/getStock?`;
    const params = new HttpParams()
    // .set('order', 'DESC')
    .set('pageSize',this.pageSize)
    .set('page', this.currentPage)
    
    let filed
    if (this.sortFiled) {
      // Map the provided field to the corresponding API attribute
      switch (this.sortFiled) {
        case 'article':
          filed = 'code_article_generique';
          break;
        case 'depot':
          filed = 'store_name';
          break;
        case 'fournisseur':
          filed = 'fournisseur_name';
          break;
        case 'physique':
          filed = 'stockQty';
          break;
          case 'achat':
            filed = 'purchasedQuantity';
            break;
        case 'taille':
          filed = 'taille';
          break;
          case 'color':
            filed = 'taille';
            break;  
        default:
          break;
      }
    }
 
    if (this.sortMode === 1) {
      url += `&orderColumn=${filed}&order=DESC`;
    } else if (this.sortMode === -1) {
      url += `&orderColumn=${filed}&order=ASC`;
    }
  

    if (queryName ) {
      url += `&queryName=${queryName}`;
  }
    if (fourniss && fourniss.length > 0) {
      const fournissParam = fourniss.join(',');

      url += `&fltrfournisseur=${fournissParam}`;
  }
  
  if (article && article.length > 0) {
    const articleParam = article.join(',');
    url += `&fltrproduct=${articleParam}`;
  }

  if (depot && depot.length > 0) {
    const depotParam = depot.join(',');
    url += `&fltrstore=${depotParam}`;
  }
  if (fltrtaille && fltrtaille.length > 0) {
    const tailleParam = fltrtaille.join(',');
    url += `&fltrtaille=${tailleParam}`;
  }

  if (fltrcolor && fltrcolor.length > 0) {
    const colorParam = fltrcolor.join(',');
    url += `&fltrcolor=${colorParam}`;
  }
    this.http
      .get(url,{params})
      .toPromise()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        this.handleApiError(error);
        throw error;      });
  });
  return promise;
}
async exportStock(fourniss?,article?,depot?,  fltrcolor?,fltrtaille?,queryName?) {
  let promise = new Promise<any>((resolve) => {
    let url = `${environment.url}/getStock?`;
    const params = new HttpParams()
    // .set('order', 'DESC')
    .set('pageSize',10000)
    .set('page', 1)
    
    let filed
    if (this.sortFiled) {
      // Map the provided field to the corresponding API attribute
      switch (this.sortFiled) {
        case 'article':
          filed = 'code_article_generique';
          break;
        case 'depot':
          filed = 'store_name';
          break;
        case 'fournisseur':
          filed = 'fournisseur_name';
          break;
        case 'physique':
          filed = 'stockQty';
          break;
          case 'achat':
            filed = 'purchasedQuantity';
            break;
        case 'taille':
          filed = 'taille';
          break;
          case 'color':
            filed = 'taille';
            break;  
        default:
          break;
      }
    }
 
    if (this.sortMode === 1) {
      url += `&orderColumn=${filed}&order=DESC`;
    } else if (this.sortMode === -1) {
      url += `&orderColumn=${filed}&order=ASC`;
    }
  

    if (queryName ) {
      url += `&queryName=${queryName}`;
  }
    if (fourniss && fourniss.length > 0) {
      const fournissParam = fourniss.join(',');

      url += `&fltrfournisseur=${fournissParam}`;
  }
  
  if (article && article.length > 0) {
    const articleParam = article.join(',');
    url += `&fltrproduct=${articleParam}`;
  }

  if (depot && depot.length > 0) {
    const depotParam = depot.join(',');
    url += `&fltrstore=${depotParam}`;
  }
  if (fltrtaille && fltrtaille.length > 0) {
    const tailleParam = fltrtaille.join(',');
    url += `&fltrtaille=${tailleParam}`;
  }

  if (fltrcolor && fltrcolor.length > 0) {
    const colorParam = fltrcolor.join(',');
    url += `&fltrcolor=${colorParam}`;
  }
    this.http
      .get(url,{params})
      .toPromise()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        this.handleApiError(error);
        throw error;      });
  });
  return promise;
    
}
}
